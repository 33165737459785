<template>
  <confirm-dialog>
    <div slot="dialogBody">
      <p>
        {{ $t('schedule.yukiAdjustJob.rearrangedVersionNotSaveYet') }}
        <br>
        {{ $t('schedule.yukiAdjustJob.saveRearrangeVersion') }}
      </p>
    </div>
    <div slot="dialogFooter">
      <schedule-button
        type="outline"
        @click="$emit('cancel')"
      >
        {{ $t('schedule.button.cancel') }}
      </schedule-button>
      <schedule-button
        @click="$emit('confirm')"
      >
        {{ $t('schedule.button.saveAndExport') }}
      </schedule-button>
    </div>
  </confirm-dialog>
</template>

<script>
import ConfirmDialog from '@/components/dialog/ConfirmDialog'

export default {
  name: 'ConfirmSaveAndExportDialog',
  components: {
    ConfirmDialog
  }
}
</script>

<style lang="scss" scoped>
::v-deep .dialog-btn-block {
  justify-content: center;
}
</style>
