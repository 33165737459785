<template>
  <div class="full-page-dialog">
    <div class="dialog-container">
      <div class="dialog-title">
        {{ $t('schedule.yukiAdjustJob.selectJobToReArrange') }}
        <div
          class="icon-close"
          @click="$emit('close')"
        >
          <svg-icon icon-class="close" />
        </div>
      </div>
      <div class="content">
        <div class="content__header">
          <div class="content__header-left">
            <button
              class="btn btn-outline"
              @click="toggleAll(true)"
            >
              {{ $t('schedule.yukiAdjustJob.selectAll') }}
            </button>
            <button
              class="btn btn-outline"
              @click="toggleAll(false)"
            >
              {{ $t('schedule.yukiAdjustJob.unSelectAll') }}
            </button>
          </div>
          <span class="content__header-right">
            {{ $t('schedule.yukiAdjustJob.jobsSelectSummary', { total: innerJobs.length, count: selectedJobs.length }) }}
          </span>
        </div>
        <div class="content__body">
          <ul class="jobs__list">
            <single-job
              v-for="job in innerJobs"
              :key="`${job.dataKey}-${job.cutId}`"
              :job="job"
              :get-property-displayed-name="getPropertyDisplayedName"
              :line-content-items="lineContentItems"
              class="jobs__item"
              :class="{ 'is-warning': incompleteJobDataKeys.includes(job.dataKey) }"
            >
              <template slot="checkbox">
                <div class="incomplete-mark">
                  <svg-icon icon-class="exclamation-triangle" />
                  {{ $t('schedule.yukiAdjustJob.incomplete') }}
                </div>
                <div class="single-job__checkbox checkbox-group">
                  <div class="checkbox-label">
                    <input
                      :id="`checkbox-${job.dataKey}-${job.cutId}`"
                      :checked="job.frontendInfo.selected"
                      type="checkbox"
                      @change="toggleJob(job)"
                    >
                    <div class="checkbox-square" />
                  </div>
                </div>
              </template>
              <div
                slot="amount-editor"
                class="amount-editor"
              >
                {{ $t('schedule.yukiAdjustJob.adjustQuantity') }}
                <el-input-number
                  v-model="job.frontendInfo.quantity"
                  :max="job.quantity"
                  :min="1"
                  class="ss-input-number"
                />
                ({{ $t('schedule.yukiAdjustJob.adjustableQuantity') }}: {{ job.quantity }})
              </div>
            </single-job>
          </ul>
        </div>
      </div>
      <div class="dialog-footer">
        <div class="dialog-button-block">
          <button
            class="btn btn-outline"
            @click="$emit('cancel')"
          >
            {{ $t('button.cancel') }}
          </button>
          <button
            v-show="selectedJobs.length > 0"
            class="btn btn-default"
            @click="onClickConfirm"
          >
            {{ $t('button.confirm') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SingleJob from './SingleJob'

export default {
  name: 'AdjustJobDialog',
  components: {
    SingleJob
  },
  props: {
    adjustJobs: {
      type: Array,
      default: () => []
    },
    lineContentItems: {
      type: Array,
      default: () => []
    },
    getPropertyDisplayedName: {
      type: Function,
      default: () => {}
    },
    incompleteJobDataKeys: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      innerJobs: []
    }
  },
  computed: {
    selectedJobs () {
      return this.innerJobs.filter(job => job.frontendInfo.selected)
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.innerJobs = JSON.parse(JSON.stringify(this.adjustJobs))
    },
    onClickConfirm () {
      this.$emit('confirm', this.innerJobs)
    },
    toggleAll (checked) {
      this.innerJobs.forEach(job => {
        job.frontendInfo.selected = checked
      })
    },
    toggleJob ({ dataKey, cutId }) {
      const job = this.innerJobs.find(job => job.dataKey === dataKey && job.cutId === cutId)
      if (job) job.frontendInfo.selected = !job.frontendInfo.selected
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-title {
  position: relative;

  .icon-close {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    font-size: 16px;
    cursor: pointer;
  }
}

.content {
  background: rgba(50, 58, 58, 0.95);
  padding: 24px;
  border-radius: 5px;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
    .btn + .btn {
      margin-left: 8px;
    }
  }

  &__body {
    max-height: 65vh;
    overflow: auto;

    .jobs {
      &__list {
        padding: 0;
        margin: 0;
        list-style: none;
      }

      &__item {
        background: rgba(67, 76, 76, 0.95);
        border-radius: 3px;
        position: relative;
        .incomplete-mark {
          display: none;
        }

        .amount-editor {
          display: flex;
          align-items: center;
          background-color: #313939;
          border-radius: 5px;
          margin-left: 55px;
          padding: 12px 16px;
          font-size: 14px;
        }

        &.is-warning {
          background-color: #4B4242;
          border: 1px solid $theme-color-danger;

          .incomplete-mark {
            position: absolute;
            display: block;
            font-size: 14px;
            display: flex;
            align-items: center;
            right: 16px;
            top: 16px;
            color: $theme-color-danger;

            .svg-icon {
              margin-right: 8px;
            }
          }
        }

        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }
  }
}

.dialog-footer {
  margin-top: 16px;
}
</style>
