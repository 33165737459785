<template>
  <li>
    {{ propertyDisplayedName }}: {{ propertyCollection }}
  </li>
</template>

<script>
export default {
  name: 'SingleJobProperty',
  props: {
    propertyCollection: {
      type: String,
      default: ''
    },
    propertyDisplayedName: {
      type: String,
      default: ''
    }
  },
  computed: {
  }
}
</script>
