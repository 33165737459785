import request from '@/schedule/utils/publicRequest.js'

/**
 * 取得子專案下加工進度表資訊
 *  @param {Number} projectId - 排程子專案 id
 */
export function getProcessInfo ({
  projectId,
  params
}) {
  return request({
    url: `/processTable/${projectId}`,
    method: 'GET',
    params
  })
}

/**
 * 取得子專案下產線資訊
 *  @param {Number} projectId - 排程子專案 id
 */
export function getProcessLines (projectId) {
  return request({
    url: `/processTable/lines/${projectId}`,
    method: 'GET'
  })
}

/**
 * 調整工單位置
 *  @param {number} data.projectId - 排程子專案 id
 *  @param {string[]} data.dataKeys - 異動製令清單
 *  @param {string} data.targetDate - 欲移動到的日期
 *  @param {string} data.targetLine - 欲移動到的產線
 */
export function rearrangePlan (data) {
  return request({
    url: '/processTable/rearrange',
    method: 'POST',
    data
  })
}

/**
 * 取得小版本重排狀態
 *  @param {Number} projectId - 排程子專案 id
 *  @param {Number} versionId - 小版本 id
 */
export function checkRearrangingStatus (projectId, versionId) {
  return request({
    url: '/processTable/rearrange/status',
    method: 'GET',
    params: {
      projectId,
      versionId
    }
  })
}

/**
 * 取消重排當中的小版本
 *  @param {Number} versionId - 小版本 id
 */
export function cancelRearrangingVersion (versionId) {
  return request({
    url: `/processTable/rearrange/${versionId}/cancel`,
    method: 'POST'
  })
}

/**
 * 儲存小版本
 *  @param {Number} projectId - 排程子專案 id
 */
export function saveRearrangedVersion (projectId) {
  return request({
    url: '/processTable/rearrange/save',
    method: 'POST',
    data: {
      projectId
    }
  })
}

/**
 * 採用小版本
 *  @param {Number} projectId - 排程子專案 id
 */
export function applySavedVersion (projectId) {
  return request({
    url: `/processTable/rearrange/${projectId}/apply`,
    method: 'POST'
  })
}

/**
 * 確認小版本採用狀態
 *  @param {Number} projectId - 排程子專案 id
 *  @param {Number} versionId - 排程子專案 id
 */
export function checkApplyingVersionStatus (project_id, version_id) {
  return request({
    url: '/processTable/rearrange/apply/status',
    method: 'GET',
    params: {
      project_id,
      version_id
    }
  })
}

/**
 * 取得當前小版本
 *  @param {Number} projectId - 排程子專案 id
 */
export function getRearrangedVersion (projectId) {
  return request({
    url: `/processTable/rearrange/${projectId}/currentVersion`,
    method: 'GET'
  })
}
