export default {
  methods: {
    // https://github.com/tangbc/vue-virtual-scroll-list/blob/master/example/src/mixins/index.js
    dispatch (componentName, eventName, ...rest) {
      let parent = this.$parent || this.$root
      let name = parent.$options.name

      while (parent && (!name || name !== componentName)) {
        parent = parent.$parent
        if (parent) {
          name = parent.$options.name
        }
      }

      if (parent) {
        parent.$emit.apply(parent, [eventName].concat(rest))
      }
    }
  }
}
