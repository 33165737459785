<template>
  <confirm-dialog>
    <div slot="dialogBody">
      <svg-icon
        icon-class="spinner-white"
        class="spinner-icon"
      />
      <br>
      <p>{{ $t('schedule.yukiAdjustJob.isExportingPlan') }}</p>
    </div>
  </confirm-dialog>
</template>

<script>
import {
  checkApplyingVersionStatus
} from '@/schedule/API/ProcessTable'
import ConfirmDialog from '@/components/dialog/ConfirmDialog'
import { mapState } from 'vuex'
import { Message } from 'element-ui'

export default {
  name: 'CheckApplyStatusDialog',
  components: {
    ConfirmDialog
  },
  props: {
    versionId: {
      type: Number,
      default: null
    }
  },
  computed: {
    ...mapState('scheduleSetting', ['scheduleProjectId'])
  },
  mounted () {
    this.checkStatus()
  },
  data () {
    return {
      timerId: null
    }
  },
  destroyed () {
    clearTimeout(this.timerId)
  },
  methods: {
    checkStatus () {
      this.timerId = setTimeout(() => {
        checkApplyingVersionStatus(this.scheduleProjectId, this.versionId)
          .then(({ status }) => {
            switch (status) {
              case 'IN_PROGRESS':
              case 'PENDING':
                this.checkStatus()
                break
              case 'COMPLETED':
                Message({
                  message: this.$t('schedule.yukiAdjustJob.applyVersionSuccess'),
                  type: 'success',
                  duration: 3 * 1000,
                  showClose: true
                })
                this.$emit('success')
                break
              case 'FAILED':
                Message({
                  message: this.$t('schedule.yukiAdjustJob.applyVersionFailed'),
                  type: 'warning',
                  duration: 3 * 1000,
                  showClose: true
                })
                this.$emit('fail')
                break
              default:
            }
          })
          .catch(() => {
            Message({
              message: this.$t('schedule.yukiAdjustJob.applyVersionFailed'),
              type: 'warning',
              duration: 3 * 1000,
              showClose: true
            })
            this.$emit('fail')
          })
          .finally(() => {})
      }, 5 * 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.spinner-icon {
  animation-name: spinner;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.confirm-dialog {
  ::v-deep .dialog-btn-block {
    justify-content: center;
  }
  ::v-deep .dialog-close-btn {
    display: none;
  }
}
</style>
