<template>
  <div
    :class="{'is-collapsed': isCollapsed}"
    class="plan-job job"
  >
    <div class="job__header">
      <h3 class="job__title">
        {{ $t('schedule.schedule.jobInfo') }}
      </h3>
      <div
        class="collapse-controller"
        @click="isCollapsed = !isCollapsed"
      >
        {{ isCollapsed ? $t('schedule.base.openCollapseItem') : $t('schedule.base.closeCollapseItem') }}
        <i class="icon el-icon-arrow-down" />
      </div>
    </div>
    <div class="job__content">
      <schedule-tab
        :active-tab="resultType"
        class="job__tabs"
        :tabs="resultTypeTabs"
        @switch="switchTab($event)"
      >
        <schedule-button
          type="outline"
          @click="downloadPlan"
          slot="right"
        >
          <svg-icon icon-class="download" />
          {{ $t('schedule.schedule.downloadPlan') }}
        </schedule-button>
      </schedule-tab>
      <keep-alive>
        <schedule-data-table
          v-if="resultType === SOLUTION_RESULT_TYPE.DELIVERY"
          key="delivery-table"
          :remote-method="getDeliveryPlanResult"
          :params="{projectId: scheduleProjectId}"
          :data-row-transfer="deliveryDataRowTransfer"
          :table-header-list="deliveryTableHeaderList"
          :restrictions="restrictions"
        />
        <schedule-data-table
          v-else
          key="detail-table"
          :remote-method="getDetailPlanResult"
          :params="{projectId: scheduleProjectId}"
          :table-header-list="detailTableHeaderList"
          :restrictions="restrictions"
        />
      </keep-alive>
    </div>
  </div>
</template>

<script>
import ScheduleTab from '@/schedule/components/ScheduleTab'
import ScheduleDataTable from '@/schedule/components/table/ScheduleDataTable'
import { getDeliveryPlanResult, getDetailPlanResult, downloadPlanExcel } from '@/schedule/API/Plan'
import { deliveryDataRowTransfer } from '@/schedule/utils/dataRowTransfer'
import { SOLUTION_RESULT_TYPE } from '@/schedule/utils/enum'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'PlanJob',
  components: {
    ScheduleTab,
    ScheduleDataTable
  },
  props: {
    planInfo: {
      type: Object,
      default: () => {}
    },
    restrictions: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      isCollapsed: false,
      resultTypeTabs: [
        {
          type: SOLUTION_RESULT_TYPE.DELIVERY,
          name: this.$t('schedule.schedule.jobDeliver')
        },
        {
          type: SOLUTION_RESULT_TYPE.DETAIL,
          name: this.$t('schedule.schedule.jobSchedule')
        }
      ],
      resultType: SOLUTION_RESULT_TYPE.DELIVERY,
      SOLUTION_RESULT_TYPE,
      getDeliveryPlanResult,
      getDetailPlanResult
    }
  },
  computed: {
    ...mapState('scheduleSetting', ['scheduleProjectId']),
    ...mapGetters('scheduleSetting', ['deliveryTableHeaderList', 'detailTableHeaderList'])
  },
  methods: {
    switchTab (type) {
      if (this.resultType === type) return
      this.resultType = type
    },
    downloadPlan () {
      this.isDownloading = true
      downloadPlanExcel({ projectId: this.scheduleProjectId, solutionId: this.planInfo.selectedSolutionId })
        .then(({ data }) => {
          const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
          if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, 'PlanResult.xlsx')
          } else {
            const link = document.createElement('a')
            if (link.download !== undefined) {
              // Browsers that support HTML5 download attribute
              const url = URL.createObjectURL(blob)
              link.setAttribute('href', url)
              link.setAttribute('download', 'PlanResult.xlsx')
              link.style.visibility = 'hidden'
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
              URL.revokeObjectURL(url)
            }
          }
        })
        .catch(() => {})
        .finally(() => this.isDownloading = false)
    },
    deliveryDataRowTransfer
  }
}
</script>

<style lang="scss" scoped>
.job {
  margin-bottom: 24px;

  &.is-collapsed {
    .job__content {
      max-height: 0;
      opacity: 0;
    }

    .icon {
      transform: rotate(180deg);
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    padding: 0 0 0 14px;
    position: relative;
  }

  &__content {
    max-height: 2000px; // 這邊設定這麼高，只是為了一定要給一個值 transition 才有效果
    opacity: 1;
    overflow: hidden;
    transition: opacity 0.2s ease, max-height 0.3s ease;
  }

  &__title {
    font-size: 18px;
    line-height: 22px;
    margin: 0;

    &::before {
      background: #2ad2e2;
      content: '';
      height: 6px;
      left: 0%;
      position: absolute;
      top: 37.5%;
      width: 6px;
    }
  }

  &__tabs {
    display: flex;
    margin-bottom: 8px;

    ::v-deep .el-tabs__header {
      flex: 1;
    }
  }

  &__table {
    ::v-deep .sy-table.el-table {
      border: 1px solid #555858;

      th,
      td {
        border-bottom: 1px solid #555858;
        border-right: 1px solid #555858;
      }
    }

    ::v-deep .el-input--small .el-input__inner {
      background-color: transparent;
      border: none;
    }
  }

  .empty-block {
    align-items: center;
    background: var(--color-bg-gray);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    height: 249px;
    justify-content: center;
  }

  .collapse-controller {
    cursor: pointer;
    font-size: 14px;

    .icon {
      transition: transform 0.3s ease;
    }
  }
}
</style>
