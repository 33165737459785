<template>
  <div>
    <spinner v-if="!processInfo && isLoading" />
    <template v-else-if="processInfo">
      <div class="adjust-plan__header">
        <div class="adjust-plan__header-title">
          {{ processInfo.title }}（{{ moment(processInfo.startDate).format('YYYY/M/D') }}, {{ processInfo.scheduleDays }}{{ $t('schedule.base.day') }}）
        </div>
        <div
          v-show="processLineOptions.length > 0"
          class="adjust-plan__header-right"
        >
          <div class="header-block has-outline">
            <div class="header-block__label">
              {{ $t('schedule.yukiAdjustJob.linesToShow') }}
              <span class="is-gray">({{ selectedLines.length }})</span>
            </div>
            <schedule-select
              ref="linesSelector"
              v-model="selectedLines"
              :options="processLineOptions"
              popper-class="lines-selector"
              multiple
            >
              <div class="line-selector__panel">
                <div class="line-selector__panel-shortcut">
                  <a
                    class="link"
                    @click="selectAllLines"
                  >
                    {{ $t('schedule.yukiAdjustJob.selectAll') }}
                  </a>
                  <a
                    class="link"
                    @click="unSelectAllLines"
                  >
                    {{ $t('schedule.yukiAdjustJob.clearSelection') }}
                  </a>
                </div>
                <schedule-button
                  class="line-selector__btn"
                  @click="applyLineSelector"
                  :is-disabled="isLoading || selectedLines.length === 0"
                >
                  {{ $t('schedule.button.apply') }}
                </schedule-button>
              </div>
            </schedule-select>
          </div>
          <div class="header-block has-outline">
            <div class="header-block__label">
              {{ $t('schedule.yukiAdjustJob.dateToShow') }}
              <span class="is-gray">{{ selectedDateRange ? selectedDateRange[0] : scheduleStartDate }}</span>
              {{ $t('schedule.base.to') }}
              <span class="is-gray">{{ selectedDateRange ? selectedDateRange[1] : scheduleEndDate }}</span>
            </div>
            <schedule-date-picker
              ref="daterangeSelector"
              v-model="selectedDateRange"
              :picker-options="dateTimePickerOptions"
              clearable
              type="daterange"
              format="yyyy/MM/dd"
              value-format="yyyy/MM/dd"
              @input="getProcessInfo"
            />
          </div>
          <div
            v-if="rearrangedVersionId"
            class="header-block save-btn"
          >
            <div
              v-show="isShowSaveVersionReminder"
              class="save-reminder"
            >
              <div class="title">
                <svg-icon icon-class="information" />
                {{ $t('schedule.yukiAdjustJob.rearrangedVersionNeedToBeSave') }}
              </div>
              <schedule-button
                size="s"
                type="outline"
                class="close-btn"
                @click="isShowSaveVersionReminder = false"
              >
                {{ $t('schedule.button.close') }}
              </schedule-button>
            </div>
            <schedule-button @click="saveVersion">
              {{ $t('schedule.button.save') }}
            </schedule-button>
          </div>
          <schedule-button
            type="outline"
            class="header-block"
            @click="exportPlan"
          >
            <svg-icon icon-class="export" />
            {{ $t('schedule.schedule.exportPlan') }}
          </schedule-button>
          <schedule-button
            v-if="processInfo.incompleteJobs.length && !isShowIncompleteJobList"
            type="outline"
            class="header-block incomplete-jobs-btn"
            @click="isShowIncompleteJobList = true"
          >
            <svg-icon icon-class="exclamation-triangle" />
            {{ $t('schedule.yukiAdjustJob.incompleteJobs') }} ({{ processInfo.incompleteJobs.length }})
          </schedule-button>
        </div>
      </div>
      <div class="adjust-plan__content">
        <yk-process-table
          :process-lines="processInfo.processLines"
          :process-data="processInfo.processTable"
          :line-content-items="lineContentItems"
          :show-spinner="isLoading"
          :adjust-job-info="adjustJobInfo"
          :get-property-displayed-name="getPropertyDisplayedName"
          :incomplete-job-data-keys="incompleteJobDataKeys"
          @cancel="resetAdjustment"
          @re-select="isShowAdjustDialog = true"
        />
        <incomplete-jobs
          v-show="processInfo.incompleteJobs.length > 0 && isShowIncompleteJobList"
          :incomplete-jobs="processInfo.incompleteJobs"
          :line-content-items="lineContentItems"
          :get-property-displayed-name="getPropertyDisplayedName"
          @close="isShowIncompleteJobList = false"
        />
      </div>
      <adjust-job-dialog
        v-if="isShowAdjustDialog"
        :adjust-jobs="adjustJobInfo.jobs"
        :line-content-items="lineContentItems"
        :get-property-displayed-name="getPropertyDisplayedName"
        :incomplete-job-data-keys="incompleteJobDataKeys"
        @confirm="onConfirmAdjustJob"
        @cancel="resetAdjustment"
        @close="isShowAdjustDialog = false"
      />
      <adjust-confirm-dialog
        v-if="isShowConfirmAdjustDialog"
        @confirm="onConfirmAdjustment"
        @close="isShowConfirmAdjustDialog = false"
      />
      <check-rearrange-status-dialog
        v-if="isCheckingRearrangingStatus"
        :version-id="rearrangingVersionId"
        @success="onRearrangeSuccess"
        @fail="clearRearrangeVersion"
        @cancel="clearRearrangeVersion"
      />
      <confirm-save-and-export-dialog
        v-if="isShowConfirmSaveAndExportDialog"
        @cancel="onCancelSaveAndExport"
        @confirm="onConfirmSaveAndExport"
      />
      <check-apply-status-dialog
        v-if="isApplyingVersionId"
        :version-id="savedRearrangeVersionId"
        @success="onApplySuccess"
        @fail="onApplyFail"
      />
    </template>
    <template v-else-if="hasError">
      <empty-info-block :msg="$t('errorMessage.SYERR0001')" />
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Message } from 'element-ui'
import {
  getProcessInfo,
  getProcessLines,
  rearrangePlan,
  saveRearrangedVersion,
  getRearrangedVersion,
  applySavedVersion
} from '@/schedule/API/ProcessTable'
import { dashToCamel } from '@/schedule/utils/utils'
import EmptyInfoBlock from '@/components/EmptyInfoBlock'
import AdjustJobDialog from './dialog/AdjustJobDialog'
import AdjustConfirmDialog from './dialog/AdjustConfirmDialog'
import CheckRearrangeStatusDialog from './dialog/CheckRearrangeStatusDialog'
import CheckApplyStatusDialog from './dialog/CheckApplyStatusDialog'
import ConfirmSaveAndExportDialog from './dialog/ConfirmSaveAndExportDialog'
import YkProcessTable from './table/ProcessTable'
import IncompleteJobs from './dialog/IncompleteJobs'
import moment from 'moment'

export default {
  name: 'YKAdjustPlan',
  components: {
    EmptyInfoBlock,
    AdjustJobDialog,
    AdjustConfirmDialog,
    CheckRearrangeStatusDialog,
    CheckApplyStatusDialog,
    ConfirmSaveAndExportDialog,
    YkProcessTable,
    IncompleteJobs
  },
  data () {
    return {
      isLoading: false,
      hasError: false,
      processInfo: null,
      processLines: [],
      selectedLines: [],
      selectedDateRange: null,
      isShowAdjustDialog: false,
      isCheckingRearrangingStatus: false,
      isShowConfirmAdjustDialog: false,
      isShowConfirmSaveAndExportDialog: false,
      isShowSaveVersionReminder: true, // 預設開啟
      isApplyingVersionId: false,
      isShowIncompleteJobList: false,
      adjustJobInfo: {},
      rearrangingVersionId: null, // 重排當中的小版本（前端暫存）
      rearrangedVersionId: null, // 重排成功的小版本（前端暫存）
      savedRearrangeVersionId: null, // 已儲存到後端的小版本
      moment
    }
  },
  computed: {
    ...mapState('scheduleSetting', ['scheduleProjectId']),
    processLineOptions () {
      return this.processLines.map(line => ({ label: line, value: line }))
    },
    scheduleStartDate () {
      if (!this.processInfo) return null
      return moment(this.processInfo.startDate).format('YYYY/MM/DD')
    },
    scheduleEndDate () {
      if (!this.processInfo) return null
      return moment(this.processInfo.startDate).add(this.processInfo.scheduleDays - 1, 'days').format('YYYY/MM/DD')
    },
    lineContentItems () {
      if (!this.processInfo) return []
      return this.processInfo.lineContentItems.reduce((acc, cur) => acc.concat(dashToCamel(cur)), [])
    },
    dateTimePickerOptions () {
      if (!this.processInfo) return () => false
      return {
        disabledDate: time => {
          return time.getTime() < moment(this.scheduleStartDate).valueOf() || time.getTime() > moment(this.scheduleEndDate).valueOf()
        }
      }
    },
    selectedJobs () {
      if (this.adjustJobInfo.jobs) {
        return this.adjustJobInfo.jobs
          .filter(({ frontendInfo }) => frontendInfo.selected)
          .map(({ dataKey, cutId, frontendInfo }) => ({
            dataKey,
            cutId,
            quantity: frontendInfo.quantity
          }))
      }
      return []
    },
    incompleteJobDataKeys () {
      if (!this.processInfo) return []
      return this.processInfo.incompleteJobs.reduce((acc, cur) => acc.concat(cur.dataKey), [])
    },
    excelURL () {
      return `${window.env.SCHEDULE_API_ROOT_URL}plan/result/excelFile?projectId=${this.$route.params.schedule_project_id}`
    }
  },
  created () {
    this.checkIfRearrangeVersionExist()
    this.getProcessLines()

    this.$on('clickAdjustJob', (payload) => {
      this.adjustJobInfo = {
        ...payload,
        line: this.processLines[payload.lineIndex]
      }
      this.isShowAdjustDialog = true
    })
    this.$on('onPlaceJob', (selectedPlacement) => {
      this.selectedPlacement = selectedPlacement
      this.isShowConfirmAdjustDialog = true
    })
  },
  methods: {
    checkIfRearrangeVersionExist () {
      this.isLoading = true

      getRearrangedVersion(this.scheduleProjectId)
        .then(({ versionId }) => {
          this.savedRearrangeVersionId = versionId
          this.getProcessInfo()
        })
        .catch(() => {})
    },
    getProcessInfo () {
      this.isLoading = true
      getProcessInfo({
        projectId: this.scheduleProjectId,
        params: this.getSearchParams()
      })
        .then(processInfo => {
          this.processInfo = processInfo
          // 沒選擇任何產線，視同選擇全部
          if (this.selectedLines.length === 0) this.setLineFilerValue()
          if (!this.selectedDateRange) this.setDateRangeFilterValue()
          this.isLoading = false
        })
        .catch(() => {
          this.hasError = true
        })
    },
    getProcessLines () {
      getProcessLines(this.scheduleProjectId)
        .then(processLines => {
          this.processLines = processLines
        })
        .catch(() => {})
        .finally(() => {})
    },
    setLineFilerValue () {
      this.selectedLines = this.processLines
    },
    setDateRangeFilterValue () {
      this.selectedDateRange = [this.scheduleStartDate, this.scheduleEndDate]
    },
    getSearchParams () {
      const searchParams = new URLSearchParams()
      this.selectedLines.forEach(line => {
        searchParams.append('lines', line)
      })
      if (this.selectedDateRange && this.selectedDateRange.length > 0) {
        searchParams.append('startDate', this.selectedDateRange[0].replaceAll('/', '-'))
        searchParams.append('endDate', this.selectedDateRange[1].replaceAll('/', '-'))
      }

      const versionId = this.rearrangedVersionId || this.savedRearrangeVersionId
      if (versionId) {
        searchParams.append('versionId', versionId)
      }
      return searchParams
    },
    applyLineSelector () {
      // 關閉產線下拉選單
      this.$refs.linesSelector.$refs.elSelect.blur()
      this.getProcessInfo()
    },
    selectAllLines () {
      this.selectedLines = this.processLines
    },
    unSelectAllLines () {
      this.selectedLines = []
    },
    exportPlan () {
      // 若有尚未儲存的小版本，先詢問是否儲存
      if (this.rearrangedVersionId) {
        this.isShowConfirmSaveAndExportDialog = true
        return
      }
      // 若有尚未採用的小版本，先進行採用
      if (this.savedRearrangeVersionId) {
        this.applyVersion()
      } else {
        this.downloadPlan()
      }
    },
    applyVersion () {
      if (this.isApplyingVersionId) return

      this.isApplyingVersionId = true
      applySavedVersion(this.scheduleProjectId)
    },
    onApplySuccess () {
      this.downloadPlan()
      this.savedRearrangeVersionId = null
      this.isApplyingVersionId = false
    },
    onApplyFail () {
      this.isApplyingVersionId = false
    },
    downloadPlan () {
      let link = document.createElement('a')
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        link.setAttribute('href', this.excelURL)
        link.setAttribute('download', 'plan.xlsx')
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    },
    getPropertyDisplayedName (propertyName) {
      switch (propertyName) {
        case 'aDate':
          return 'A'
        case 'bDate':
          return 'B'
        case 't4Date':
        case 't4date':
          return 'T4'
        default:
          return this.$t(`schedule.simulation.yukiTable.${propertyName}`)
      }
    },
    onConfirmAdjustJob (mutatedJobsInfo) {
      this.adjustJobInfo.jobs = mutatedJobsInfo
      this.isShowAdjustDialog = false
    },
    onConfirmAdjustment () {
      const postAdjustmentPayload = {
        projectId: this.scheduleProjectId,
        versionId: this.rearrangedVersionId,
        sources: this.selectedJobs,
        targetDate: this.selectedPlacement.date,
        targetLine: this.processLines[this.selectedPlacement.lineIndex]
      }
      this.isShowConfirmAdjustDialog = false
      this.isCheckingRearrangingStatus = true

      rearrangePlan(postAdjustmentPayload)
        .then(({ versionId }) => {
          this.rearrangingVersionId = versionId
          this.isCheckingRearrangingStatus = true
        })
        .catch(() => {})
        .finally(() => {})
    },
    resetAdjustment () {
      this.adjustJobInfo = {}
      this.selectedPlacement = {}
      this.isShowAdjustDialog = false
    },
    onRearrangeSuccess () {
      this.isCheckingRearrangingStatus = false
      this.rearrangedVersionId = this.rearrangingVersionId
      this.getProcessInfo()

      // 清空資料
      this.resetAdjustment()
      this.rearrangingVersionId = null
    },
    clearRearrangeVersion () {
      this.isCheckingRearrangingStatus = false
      this.rearrangingVersionId = null
    },
    saveVersion () {
      if (this.isSavingVersion) return

      this.isSavingVersion = true
      return saveRearrangedVersion(this.scheduleProjectId)
        .then(() => {
          Message({
            message: this.$t('schedule.yukiAdjustJob.rearrangeVersionBeingSaved'),
            type: 'success',
            duration: 3 * 1000,
            showClose: true
          })
          this.adjustJobInfo = {}
          this.savedRearrangeVersionId = this.rearrangedVersionId
          this.rearrangedVersionId = null
        })
        .catch(() => {})
        .finally(() => {
          this.isSavingVersion = false
        })
    },
    onCancelSaveAndExport () {
      this.isShowConfirmSaveAndExportDialog = false
    },
    async onConfirmSaveAndExport () {
      await this.saveVersion()
      this.exportPlan()
      this.isShowConfirmSaveAndExportDialog = false
    }
  }
}

</script>

<style lang="scss" scoped>
.adjust-plan {
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    &-title {
      font-size: 20px;
      line-height: 40px;
    }
    &-right {
      display: flex;
      align-items: flex-end;
      font-size: 14px;

      .header-block {
        position: relative;
        border-radius: 4px;
        padding: 5px 12px;

        &:not(:last-child) {
          margin-right: 8px;
        }

        &.has-outline {
          border: 1px solid #FFF;
        }

        &.save-btn {
          position: relative;
          padding: 0;

          .save-reminder {
            width: 234px;
            position: absolute;
            bottom: 105%;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            background: #DFA935;
            padding: 8px 16px;
            border-radius: 6px;
            z-index: 1000;
            color: #FFF;

            &::after {
              content: '';
              position: absolute;
              top: 100%;
              left: 50%;
              width: 0;
              height: 0;
              transform: translateX(-50%);
              border-left: 10px solid transparent;
              border-right: 10px solid transparent;
              border-top: 10px solid #DFA935;
            }

            .title {
              display: flex;
              align-items: center;
              margin: 4px 0 6px 0;

              .svg-icon {
                margin-right: 4px;
              }
              .schedule-button {
                width: 80px;
              }
            }

            .close-btn {
              width: 50px;
              height: 30px;
              border-radius: 4px;
            }
          }
        }

        &.incomplete-jobs-btn {
          color: $theme-color-danger;
          border-color: $theme-color-danger;
        }

        ::v-deep .schedule-select {
          width: 100px;
          .el-input {
            .el-input__inner {
              opacity: 0;
              height: 24px;
              line-height: 24px;
            }

            .el-input__suffix {
              display: none;
            }
            }
          .el-select {
            .el-select__tags {
            display: none;
            }
            .el-input__inner {
              border-bottom: none;
            }
          }
        }

        ::v-deep .schedule-date-picker {
          width: 260px;
          border-bottom: none;
          .el-date-editor {
            height: 24px;
            opacity: 0;
          }
        }

        &__label {
          position: absolute;
          cursor: pointer;
        }
      }

      .schedule-select {
        padding-bottom: 0;
      }
    }
  }

  &__content {
    background-color: rgba(35, 61, 64, 0.6);
    display: flex;
    gap: 8px;

    .yk-table {
      flex: 1;
    }

    .incomplete-jobs {
      flex-basis: 240px;
    }
  }
}

.is-gray {
  color: #CCC;
}
</style>
<style lang="scss">
.lines-selector {
  position: relative;
  min-width: 200px !important;

  .el-scrollbar__view.el-select-dropdown__list {
    padding-bottom: 108px; // line-selector__panel 的高度
  }

  .line-selector__panel {
    padding: 12px 20px 20px 20px;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: rgb(48, 52, 53);

    &-shortcut {
      margin-bottom: 12px;
      .link {
        text-decoration: none;
        margin-right: 12px;
      }
    }
  }
}
</style>
