<template>
  <div class="reminder">
    <span>{{ $t('schedule.yukiAdjustJob.selected') }}</span>
    <span>{{ $t('schedule.yukiAdjustJob.jobCount', { count: info.count } ) }}</span>
    <svg-icon
      icon-class="checked"
      class="checked-icon"
    />
  </div>
</template>

<script>
export default {
  name: 'JobSelectedReminder',
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
.reminder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  border: 2px solid $theme-color-primary;
  z-index: 1;

  .checked-icon {
    position: absolute;
    top: -2px;
    right: -2px;
    fill: #fff;
    background-color: $theme-color-primary;
    padding: 3px;
    border-radius: 0 0 0 5px;
    font-size: 12px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(35, 61, 64, 0.8);
    z-index: -1;
  }
}
</style>
