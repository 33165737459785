<template>
  <div
    class="reminder is-placable"
    @click="$emit('place-job')"
  >
    <div>{{ $t('schedule.yukiAdjustJob.clickBlock') }}</div>
    <div>{{ $t('schedule.yukiAdjustJob.reArrangeToThisBlock') }}</div>
  </div>
</template>

<script>
export default {
  name: 'JobPlacableReminder'
}
</script>

<style lang="scss" scoped>
.reminder {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $theme-color-primary;
  color: #fff;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: opacity .3s ease;
}
</style>
