<template>
  <div
    class="yk-table"
    :class="{ 'is-adjusting': isAdjustingMode }"
  >
    <spinner v-show="showSpinner" />
    <ol
      id="label"
      class="yk-column is-line-label"
    >
      <li class="yk-column__cell">
        {{ $t('schedule.base.date') }}
      </li>
      <li
        v-for="lineName in lineColumn.lines"
        :key="lineName"
        class="yk-column__cell"
      >
        <span>{{ lineName }}</span>
      </li>
    </ol>
    <virtual-list
      id="table"
      class="virtual-list"
      data-key="date"
      direction="horizontal"
      wrap-class="yk-table__list-wrapper"
      item-class="yk-table__list-item"
      :estimate-size="120"
      :data-sources="processData"
      :data-component="YkProcessColumn"
      :extra-props="extraProps"
    />
    <adjust-tooltip
      v-if="isAdjustingMode"
      @re-select="$emit('re-select')"
      @cancel="$emit('cancel')"
    />
  </div>
</template>

<script>
import VirtualList from 'vue-virtual-scroll-list'
import YkProcessColumn from './ProcessColumn'
import AdjustTooltip from './AdjustTooltip'

import { mapState } from 'vuex'

export default {
  name: 'ProcessTable',
  components: {
    VirtualList,
    YkProcessColumn,
    AdjustTooltip
  },
  props: {
    processLines: {
      type: Array,
      default: () => []
    },
    processData: {
      type: Array,
      default: () => []
    },
    lineContentItems: {
      type: Array,
      default: () => []
    },
    showSpinner: {
      type: Boolean,
      default: true
    },
    adjustJobInfo: {
      type: Object,
      default: () => {},
      required: true
    },
    incompleteJobDataKeys: {
      type: Array,
      default: () => []
    },
    getPropertyDisplayedName: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      isSavingVersion: false,
      processTable: [],
      scrollTop: 0,
      YkProcessColumn
    }
  },
  computed: {
    ...mapState('scheduleSetting', ['scheduleProjectId']),
    lineColumn () {
      return {
        lines: this.processLines
      }
    },
    extraProps () {
      return {
        lineCount: this.processLines.length,
        currentYear: new Date().getFullYear(),
        lineContentItems: this.lineContentItems,
        getPropertyDisplayedName: this.getPropertyDisplayedName,
        isAdjustingMode: this.isAdjustingMode,
        incompleteJobDataKeys: this.incompleteJobDataKeys,
        jobSelectedInfo: {
          date: this.adjustJobInfo.date,
          lineIndex: this.adjustJobInfo.lineIndex,
          count: this.selectedJobCount
        }
      }
    },
    selectedJobCount () {
      if (this.adjustJobInfo.jobs) return this.adjustJobInfo.jobs.filter(job => job.frontendInfo.selected).length
      return 0
    },
    isAdjustingMode () {
      return this.selectedJobCount > 0
    }
  },
  mounted () {
    const lineColumnNode = document.getElementById('label')
    const processTableNode = document.getElementById('table')
    if (lineColumnNode && processTableNode) {
      lineColumnNode.addEventListener('scroll', ({ target }) => this.scrollHandler(target.scrollTop, processTableNode))
      processTableNode.addEventListener('scroll', ({ target }) => this.scrollHandler(target.scrollTop, lineColumnNode))
    }
  },
  methods: {
    scrollHandler (newScrollTop, nodeToSync) {
      // only trigger while scroll vertically
      if (newScrollTop === this.scrollTop) return

      // keep lineLabelColumn div && processTable div scroll in sync
      nodeToSync.scrollTop = newScrollTop
      this.scrollTop = newScrollTop
    }
  }
}

</script>

<style lang="scss" scoped>
.virtual-list {
  width: 100%;
  overflow: overlay;
}

.reminder {
  color: $theme-color-warning;
  font-size: 14px;
}
</style>
