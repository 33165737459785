<template>
  <confirm-dialog>
    <div slot="dialogBody">
      {{ $t('schedule.yukiAdjustJob.otherJobWillBeReArranged') }}
      <br>
      {{ $t('schedule.yukiAdjustJob.sureToReArrange') }}
    </div>
    <div slot="dialogFooter">
      <schedule-button
        type="outline"
        @click="$emit('close')"
      >
        {{ $t('schedule.button.cancel') }}
      </schedule-button>
      <schedule-button
        @click="$emit('confirm')"
      >
        {{ $t('schedule.button.confirm') }}
      </schedule-button>
    </div>
  </confirm-dialog>
</template>

<script>
import ConfirmDialog from '@/components/dialog/ConfirmDialog'

export default {
  name: 'AdjustConfirmDialog',
  components: {
    ConfirmDialog
  }
}
</script>
