
<template>
  <div class="current-simulation">
    <h2 class="header">
      <div class="header-left">
        {{ $t('schedule.schedule.title') }}
      </div>
      <div
        v-if="planInfo.planId"
        class="header-right"
      >
        <schedule-button
          v-if="!isYKSchedule"
          :is-disabled="isProcessingProductionProgress"
          type="secondary"
          @click="syncProductionProgress"
        >
          {{ $t('schedule.rolling.syncProductionProgress') }}
        </schedule-button>
        <schedule-button
          :is-disabled="isLoadingLastSolution"
          type="secondary"
          @click="reSimulate"
        >
          {{ $t('schedule.schedule.reSimulate') }}
        </schedule-button>
      </div>
    </h2>
    <jobs-filter
      v-if="!isLoading && planInfo.planId && !isYKSchedule"
      :job-states-options="jobStates"
      @submit="updateRestrictions"
    />
    <spinner v-if="isLoading" />
    <div
      v-else-if="planInfo.planId"
      :class="isYKSchedule ? 'iframe-container' : 'simulation-content'"
    >
      <template
        v-if="isYKSchedule"
      >
        <yk-adjust-plan />
      </template>
      <template
        v-else
      >
        <div class="plan-KPI KPI">
          <h3 class="KPI__title">
            {{ $t('schedule.schedule.planKPI') }}
          </h3>
          <div class="KPI__info">
            <span class="KPI__info--item">
              {{ $t('schedule.schedule.dateRange') }}：{{ KPIInfo.timeRange }}
            </span>
            <span class="KPI__info--item">
              {{ $t('schedule.schedule.capacity') }}：{{ formatComma(KPIInfo.capacity) }}個
            </span>
            <span class="KPI__info--item">
              {{ $t('schedule.schedule.ofr') }}：{{ KPIInfo.ofr }}％
            </span>
            <span class="KPI__info--item">
              {{ $t('schedule.schedule.utilization') }}：{{ KPIInfo.utilization }}％
            </span>
          </div>
        </div>
        <plan-job
          :plan-info="planInfo"
          :restrictions="restrictions"
        />
        <plan-gantt
          :restrictions="restrictions"
          :plan-info="planInfo"
          :job-states="jobStates"
        />
      </template>
    </div>
    <div
      v-else
      class="empty-block"
    >
      <img
        class="empty-block__icon"
        src="@/schedule/icons/empty.svg"
      >
      <span class="empty-block__text"> {{ $t('schedule.schedule.noPlan') }} </span>
      <schedule-button
        class="empty-block__btn"
        @click="simulateNewPlan"
      >
        {{ $t('schedule.button.simulateNewPlan') }}
      </schedule-button>
    </div>
  </div>
</template>

<script>
import PlanJob from './components/PlanJob'
import PlanGantt from './components/PlanGantt'
import JobsFilter from '@/schedule/components/JobsFilter'
import YkAdjustPlan from './ykAdjustPlan/AdjustPlan'
import { JOB_STATUS } from '@/schedule/utils/enum'
import { getPlanInfo, getPlanKPI, getLastSolutions } from '@/schedule/API/Plan'
import { Message } from 'element-ui'
import { mapMutations, mapState, mapGetters } from 'vuex'

export default {
  name: 'CurrentSimulation',
  components: {
    PlanJob,
    PlanGantt,
    JobsFilter,
    YkAdjustPlan
  },
  data () {
    return {
      isLoading: false,
      isLoadingLastSolution: false,
      isProcessingProductionProgress: false,
      planInfo: {},
      KPIInfo: {
        capacity: 0,
        ofr: 0,
        utilization: 0,
        timeRange: ''
      },
      restrictions: {}
    }
  },
  computed: {
    ...mapState('scheduleSetting', ['scheduleProjectId']),
    jobStates () {
      return [
        JOB_STATUS.DELAY,
        JOB_STATUS.OVERDUE,
        JOB_STATUS.OVERDUE_DELAY
      ]
    },
    ...mapGetters('scheduleSetting', ['isYKSchedule'])
  },
  mounted () {
    this.fetchData()
  },
  created () {
    this.setCurrentProjectId(Number(this.$route.params.schedule_project_id))
  },
  beforeDestroy () {
    this.updateScheduledJobs([])
  },
  methods: {
    ...mapMutations('simulation', ['updateScheduledJobs']),
    ...mapMutations('scheduleSetting', ['setCurrentProjectId']),
    async fetchData () {
      this.isLoading = true
      this.planInfo = await getPlanInfo(this.scheduleProjectId)
      if (!this.planInfo.planId) {
        this.isLoading = false
        return
      }
      this.$store.commit('scheduleSetting/setHasCurrentPlan', true)
      getPlanKPI(this.scheduleProjectId)
        .then(res => {
          this.KPIInfo = res
          this.KPIInfo.ofr = Number(this.KPIInfo.ofr * 100).toFixed(2)
          this.KPIInfo.utilization = Number(this.KPIInfo.utilization * 100).toFixed(2)
          this.KPIInfo.timeRange = this.planInfo.startDate + '-' + this.planInfo.endDate
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    simulateNewPlan () {
      this.$router.push({ name: 'SimulationSetting' })
    },
    syncProductionProgress () {
      this.isProcessingProductionProgress = true
      this.$store.dispatch('scheduleSetting/syncProductionProgress')
        .then(() => {
          Message({
            message: this.$t('schedule.successMessage.syncProductionProgressSuccessfully'),
            type: 'success',
            duration: 3 * 1000,
            showClose: true
          })
        })
        .catch(() => {})
        .finally(() => {
          this.isProcessingProductionProgress = false
        })
    },
    reSimulate () {
      this.isLoadingLastSolution = true
      const fetchDefaultSetting = this.$store.dispatch('scheduleSetting/getBaseSetting')
      const fetchLastSolutionsSetting = getLastSolutions(this.scheduleProjectId)

      Promise.all([fetchDefaultSetting, fetchLastSolutionsSetting])
        .then(([defaultSetting, lastSolutions]) => {
          // 從當前計畫返回模擬，會清除當前任何模擬進度
          this.$store.commit('simulation/setPlanId', null)

          // 整理 solutions 格式、排除子模擬方案
          const formattedSolutions = lastSolutions
            .map(solution => {
              // NOTICE 邏輯重複，在 sp2 已整進 actions 中
              // 不是鈺齊環境，要把 worktimes 格式統一成新版（含 hours, ranges）
              const formattedWorktimes = {}
              for (const [weekday, shifts] of Object.entries(solution.worktimes)) {
                formattedWorktimes[weekday] = {
                  hours: 0,
                  ranges: shifts
                }
              }
              return {
                ...defaultSetting,
                ...solution,
                worktimes: this.isYKSchedule ? solution.worktimes : formattedWorktimes,
                simulated: true,
                errorMessages: []
              }
            })
            .filter(solution => this.isParentSolution(solution.sequence))

          this.$store.commit('simulation/setSolutions', formattedSolutions)

          this.$router.push({ name: 'SimulationSetting' })
        }).finally(() => { this.isLoadingLastSolution = false })
    },
    updateRestrictions (newVal) {
      this.restrictions = newVal
    },
    isParentSolution (sequence) {
      // sequence 為整數代表為 parent solution
      return Number.isInteger(sequence)
    }
  }
}
</script>

<style lang="scss" scoped>
.current-simulation {
  height: 100%;
  overflow: auto;
  overflow: overlay;
  padding: 24px;
  position: relative;

  .header {
    display: flex;
    justify-content: space-between;
    margin: 0 0 12px;

    &-left {
      display: flex;

      .filter {
        margin-left: 16px;
      }
    }

    &-right {
      .default-button + .default-button {
        margin-left: 8px;
      }
    }
  }

  .jobs__filter {
    margin-bottom: 24px;
  }

  .iframe-container {
    height: 78vh;
  }

  .button-block {
    position: absolute;
    right: 24px;
    top: 24px;
  }

  .KPI {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;
    padding: 0 14px;
    position: relative;

    &__title {
      font-size: 18px;
      line-height: 22px;
      margin: 0 30px 0 0;

      &::before {
        background: #2ad2e2;
        content: '';
        height: 6px;
        left: 0%;
        position: absolute;
        top: 37.5%;
        width: 6px;
      }
    }

    &__info {
      color: var(--color-text-light);
      font-size: 14px;
      line-height: 18px;
      margin-right: 24px;

      &--item {
        &:not(:last-child) {
          margin-right: 24px;
        }
      }
    }
  }

  .empty-block {
    align-items: center;
    background: var(--color-bg-gray);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    height: 249px;
    justify-content: center;

    &__icon {
      margin-bottom: 16px;
    }

    &__text {
      color: var(--color-text-gray);
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 16px;
    }

    &__btn {
      width: 260px;
    }
  }
}
</style>
