<template>
  <ol class="yk-column">
    <li class="yk-column__cell">
      {{ displayedDate }}
    </li>
    <!-- 無產線資訊 -->
    <template v-if="source.lines === null || source.lines.length === 0">
      <li
        v-for="index in lineCount"
        :key="index"
        class="yk-column__cell with-diagonal"
      />
    </template>
    <!-- 有產線資訊，渲染製令清單 -->
    <template v-else>
      <li
        v-for="(content, index) in source.lines"
        :key="index"
        class="yk-column__cell"
        :class="{ 'is-warning': hasIncompleteJob(content) }"
      >
        <job-selected-reminder
          v-if="isCellSelected(jobSelectedInfo, index)"
          :info="jobSelectedInfo"
        />
        <job-placable-reminder
          v-else-if="isAdjustingMode"
          @place-job="onPlaceJob(index)"
        />
        <template v-if="content.length > 0">
          <el-tooltip
            v-if="!isAdjustingMode"
            :content="$t('schedule.yukiAdjustJob.changePosition')"
            placement="top"
          >
            <div
              class="setting-icon"
              @click="clickAdjustJob(source.lines[index], index)"
            >
              <svg-icon icon-class="setting" />
            </div>
          </el-tooltip>
          <ol class="property-collection__list">
            <single-job-property
              v-for="(propertyCollection, propertyName) in getPropertyCollection(content)"
              class="property-collection__item"
              :key="propertyName"
              :property-collection="propertyCollection"
              :property-displayed-name="getPropertyDisplayedName(propertyName)"
            />
          </ol>
        </template>
      </li>
    </template>
  </ol>
</template>

<script>
import SingleJobProperty from './SingleJobProperty'
import JobSelectedReminder from './JobSelectedReminder'
import JobPlacableReminder from './JobPlacableReminder'
import mixins from '@/schedule/utils/mixins'
import moment from 'moment'

export default {
  name: 'HorizontalItem',
  mixins: [mixins],
  components: {
    SingleJobProperty,
    JobSelectedReminder,
    JobPlacableReminder
  },
  props: {
    source: {
      type: Object,
      default: () => {}
    },
    lineCount: {
      type: Number,
      default: 0
    },
    currentYear: {
      type: Number,
      default: 0
    },
    lineContentItems: {
      type: Array,
      default: () => []
    },
    getPropertyDisplayedName: {
      type: Function,
      default: () => {}
    },
    jobSelectedInfo: {
      type: Object,
      default: () => {}
    },
    isAdjustingMode: {
      type: Boolean,
      default: false
    },
    incompleteJobDataKeys: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    displayedDate () {
      if (!this.source.date) return ''
      if (this.source.date.slice(0, 4) == this.currentYear) {
        return moment(this.source.date).format('M/D')
      }
      return moment(this.source.date).format('YYYY/M/D')
    }
  },
  methods: {
    getPropertyCollection (jobs) {
      const result = {}
      this.lineContentItems.forEach(propertyName => {
        let propertyCollection = new Set()
        jobs.forEach(job => {
          const propertyValue = job[propertyName]
          if (!propertyName) return
          if (propertyName === 'deadline') {
            const subDeadlines = propertyValue.split(', ')
            subDeadlines.forEach(deadline => {
              propertyCollection.add(deadline)
            })
          } else {
            propertyCollection.add(propertyValue)
          }
        })
        result[propertyName] = Array.from(propertyCollection).join(', ')
      })
      return result
    },
    clickAdjustJob (jobs, lineIndex) {
      this.dispatch('YKAdjustPlan', 'clickAdjustJob', {
        date: this.source.date,
        lineIndex,
        jobs: jobs.map(job => ({
          ...job,
          frontendInfo: {
            selected: false,
            quantity: job.quantity
          }
        }))
      })
    },
    onPlaceJob (lineIndex) {
      this.dispatch('YKAdjustPlan', 'onPlaceJob', {
        date: this.source.date,
        lineIndex
      })
    },
    isCellSelected ({ date, lineIndex, count }, index) {
      return date === this.source.date &&
        lineIndex === index &&
        count > 0
    },
    hasIncompleteJob (jobs) {
      return jobs.some(({ dataKey }) => this.incompleteJobDataKeys.includes(dataKey))
    }
  }
}
</script>

<style lang="scss" scoped>
.property-collection {
  &__list {
    padding: 0;
    list-style: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;

    &:hover {
      overflow: overlay;
    }
  }
  &__item {
    position: relative;
    margin: 8px;

    &:not(:last-child) {
      padding-bottom: 4px;
      border-bottom: 1px dashed #515959;
      margin-bottom: 4px;
    }
  }
}
</style>
