<template>
  <div class="adjust-tooltip">
    <div class="adjust-tooltip__label">
      {{ $t('schedule.yukiAdjustJob.selectNewTargetPosition') }}
    </div>
    <div
      class="adjust-tooltip__btn btn-reset"
      @click="$emit('re-select')"
    >
      {{ $t('schedule.yukiAdjustJob.reSelectJob') }}
    </div>
    <div
      class="adjust-tooltip__btn btn-cancel"
      @click="$emit('cancel')"
    >
      {{ $t('schedule.button.cancel') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdjustTooltip'
}
</script>

<style lang="scss" scoped>
.adjust-tooltip {
  position: absolute;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  z-index: 3;
  padding: 5px 12px;
  border-radius: 5px;
  border: 1px solid $theme-color-primary;;
  box-shadow: 0px 4px 10px 0px #3AB2BD80;
  background: rgba(23, 23, 23, 0.8);
  font-size: 14px;

  &__btn {
    margin-left: 8px;
    color: $theme-color-primary;
    cursor: pointer;
  }
}
</style>
