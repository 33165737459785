<template>
  <li class="single-job">
    <label
      :for="`checkbox-${job.dataKey}-${job.cutId}`"
      class="single-job__label"
    >
      <slot name="checkbox" />
      <div class="single-job__detail">
        <div
          v-for="propertyName in lineContentItems"
          :key="propertyName"
          class="single-job__property"
        >
          {{ getPropertyDisplayedName(propertyName) }}:
          {{ job[propertyName] }}
        </div>
      </div>
    </label>
    <slot name="amount-editor" />
  </li>
</template>

<script>
export default {
  name: 'SingleJob',
  props: {
    job: {
      type: Object,
      default: () => {}
    },
    lineContentItems: {
      type: Array,
      default: () => []
    },
    getPropertyDisplayedName: {
      type: Function,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
.single-job {
  padding: 0 16px 16px 0;
  &__label {
    padding: 20px;
    display: flex;
    align-items: flex-start;
    cursor: pointer;
  }

  &__detail {
    flex: 1;
  }

  &__checkbox {
    margin-right: 20px;
  }
  &__property {
    display: flex;
    color: #CCC;
    font-size: 13px;
    line-height: 1;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}
</style>
