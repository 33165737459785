<template>
  <div class="incomplete-jobs">
    <div class="incomplete-jobs__header">
      <svg-icon
        class="exclamation-icon"
        icon-class="exclamation-triangle"
      />
      <span>{{ $t('schedule.yukiAdjustJob.incompleteJobs') }}</span>({{ incompleteJobs.length }})
      <div
        class="close-icon"
        @click="$emit('close')"
      >
        <svg-icon icon-class="close" />
      </div>
    </div>
    <ul class="incomplete-jobs__list">
      <div
        v-for="(job, index) in incompleteJobs"
        :key="index"
        class="job"
      >
        <single-job
          :job="job"
          :get-property-displayed-name="getPropertyDisplayedName"
          :line-content-items="lineContentItems"
        />
      </div>
    </ul>
  </div>
</template>

<script>
import SingleJob from './SingleJob'

export default {
  name: 'IncompleteJobs',
  components: {
    SingleJob
  },
  props: {
    incompleteJobs: {
      type: Array,
      default: () => []
    },
    lineContentItems: {
      type: Array,
      default: () => []
    },
    getPropertyDisplayedName: {
      type: Function,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
$padding-x: 16px;
.incomplete-jobs {
  border: 1px solid #555858;
  height: 75vh;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    font-size: 14px;
    align-items: center;
    background: #475353;
    padding: 12px $padding-x;

    .exclamation-icon {
      margin-right: 8px;
    }

    .close-icon {
      margin-left: auto;
      font-size: 12px;
      cursor: pointer;
      display: flex;
      align-items: center;
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    background: rgba(38, 46, 46, 0.95);
    overflow: auto;
    flex: 1;

    .job {
      position: relative;

      &:only-child, &:not(:last-child) {
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: $padding-x;
          right: $padding-x;
          height: 1px;
          background-color: #515959;
        }
      }
      ::v-deep .single-job {
        &__label {
          cursor: default;
        }

      }
    }
  }
}
</style>
